import $ from 'jquery'
import firebase from './firebase'
import storage from '../storage'

export const post = async (url, body) => {
  const startTime = new Date().getTime()
  try {
    const res = await $.post(url, body)
    firebase.firestore.collection('log').add({
      url: url || null,
      params: body || null,
      success: true,
      timeLength: new Date().getTime() - startTime,
      userId: storage.getItem('LINEMid') || '',
      createdAt: new Date()
    }).catch()
    return res
  } catch (error) {
    firebase.firestore.collection('log').add({
      url: url || null,
      params: body || null,
      success: false,
      timeLength: new Date().getTime() - startTime,
      userId: storage.getItem('LINEMid') || '',
      createdAt: new Date()
    }).catch()
    throw error
  }
}
